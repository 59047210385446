@media screen and (max-width: 640px) {
  .only-desktop {
    display: none !important;
  }
  .menubar {
    position: fixed;
    top: 50px;
    left: 4px;
    display: flex;
    align-items: flex-start;
    width: 60%;
    height: fit-content;
    background: #FFF;
    backdrop-filter: blur(3px);
    border-radius: 10px;
    transition: all 0.5s ease-in;
    z-index: 100;
  }
  .active {
    left: 0;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  
  .menubar ul {
    padding: 0;
    list-style: none;
  }
  .menubar ul li {
    margin-bottom: 32px;
  }
  
  .menubar ul li a {
    text-decoration: none;
    color: #000;
    font-size: 95%;
    font-weight: 400;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .menubar ul li a:hover {
    background-color: #f5f5f5;
  }

  nav {
    flex-direction: column;
    padding: 0;
    height: 80px;
  }
      nav .logo img {
          height: 35px;
      }
    .hamburger {
      position: absolute;
      display: block;
      left: 15px;
      top: 12px;
      filter: drop-shadow(0px 0px 3px white);
    }
    .hamburger .line {
      height: 6px;
      width: 30px;
      border-radius: 4px;
      border: 1px solid black;
    }
    .hamburger-active .line {
      background-color: #000;
    }
    /* .hamburger .line:nth-of-type(1) {
      background-color: #e22028;
    }
    .hamburger .line:nth-of-type(2) {
      background-color: white;
    }
    .hamburger .line:nth-of-type(3) {
      background-color: #223fd3;
    } */
    nav ul {
      display: none;
    }
    nav .social-links{
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
      padding: 0;
      margin: 0;
      align-self: flex-end;
    }
    nav .social-links li a img {
      width: 30px;
      height: 30px;
    }
    nav .social-links li .buy {
      width: 125px;
    }
    .container {
      overflow: hidden;
      top: 80px;
    }
    .container .home{
      height: 140vw;
    }
    .container .home .faces {
      top: 10vw;
    }
    .container .home .faces .faceRow:nth-of-type(2) img:nth-of-type(2) {
      transform: translate(-3vw, -9vw);
    }
    .container .console {
      top: 90px;
    }
    .container .console #flag {
      width: 35%;
      position: absolute;
      top: 0;
      z-index: -1;
    }
    .container .console #flag:nth-last-of-type(1){
      right: 0;
      transform: rotateZ(-10deg) scaleX(-100%);
    }
    .container .console #flag:nth-last-of-type(2){
      left: 0;
      transform: rotateZ(10deg);
    }
    .container .console .consoleLayer .consoleTop #timer .txt{
      font-size: 3vw;
      line-height: 3.2vw;
      margin-top: 2vw;
    }
    .container .console .consoleLayer .consoleTop #timer #timerDisplay{
      margin: 0;
    }
    .container .console .consoleLayer .consoleTop #timer #timerDisplay em {
      font-size: 2vw;
    }
    .container .console .consoleLayer .consoleTop #timer {
      padding-top: 10vw;
      width: 50vw;
      height: 18vw;
      font-size: 8px;
    }
    .container .console img{
      width: 100%;
    }
    .container .home .faces img{
      width: 100px;
    }
    .container .home .faces .faceRow:nth-of-type(2){
      width: fit-content;
    }
    .container .home .faces .faceRow:nth-of-type(1),.container .home .faces .faceRow:nth-of-type(3){
      top: 45px;
    }
    .container .home .faces .faceRow:nth-of-type(1) img,.container .home .faces .faceRow:nth-of-type(3) img{
      padding-top: 80px;
      width: 90px;
    }
    .container .home .faces .faceRow:nth-of-type(1) img:nth-of-type(2),.container .home .faces .faceRow:nth-of-type(3) img:nth-of-type(2){
      padding-top: 110px;
    }
    .container .home .faces .faceRow:nth-of-type(1) img:nth-of-type(3),.container .home .faces .faceRow:nth-of-type(3) img:nth-of-type(3){
      padding-top: 130px;
    }
    .container .home .console .consoleLayer .consoleCenter .top{
      width:100%;
      padding-top: 4%;
    }
    .container .home .console .consoleLayer .consoleCenter .top .progressBar span{
      height: 100%;
      font-size: 8px;
    }
    .container .home .console .consoleLayer .consoleCenter .top h2{
      font-size: 4vw;
    }
    .container .home .console .consoleLayer .consoleCenter .center {
      position: absolute;
      width: 100%;
      top: 185px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }
    .container .home .console .consoleLayer .consoleCenter .center h2{
      font-size: 4vw;
      margin: 0;
      line-height: 1.2;
    }
    .container .home .console .consoleLayer .consoleCenter .center h3{
      font-size: 6vw;
      margin: 10px 0;
      color: white;
    }
    .container .home .console .consoleLayer .consoleCenter .center p{
      display: none;
    }
    .container .home .console .consoleLayer .consoleCenter .bottom{
      width: 100%;
      padding-top: 25%;
    }
    .container .home .console .consoleLayer .consoleCenter .bottom img {
      width: 15.6vw;
      height: 15.6vw;
    }
    .container .home .console .consoleLayer .consoleBottom{
      width: 100%;
    }
    .container .home .console .consoleLayer .consoleBottom .left,.container .home .console .consoleLayer .consoleBottom .right{
      width: 50%;
    }
    .container .home .console .consoleLayer .consoleBottom .left p, .container .home .console .consoleLayer .consoleBottom .right p {
      font-size: 3vw;
    }
    .container .home .console .consoleLayer .consoleBottom .left div img, .container .home .console .consoleLayer .consoleBottom .right div img {
      width: 5vw;
    }
    .container .home .console .consoleLayer .consoleBottom .left div input, .container .home .console .consoleLayer .consoleBottom .right div input {
      font-size: 4vw;
    }
    .container .home .console .consoleLayer .consoleBottom button {
      height: auto;
      font-size: 4.5vw;
      padding: 2vw 6vw;
    }
    .container .About div h2{
      font-size: 25px;
      margin: 0;
    }
  .container .About div p{
      font-size: 12px;
      padding: 0 5%;
      background-color: #eae7e1ab;
      border-radius: 10px;
      margin: 0;
  }
  .container .About div{
      width: 100%;
      right: 0;
  }
  .container .About #people {
      padding-top: 11px;
  }
  .container .Whitehouse {
    height: 500px;
  }
  .container .Whitehouse .flag-container{
    padding-top: 10px;
  }
  .container .Whitehouse .house {
    width: 100%;
    margin-top: 0;
  }
  .container .Whitehouse .memes {
    width: 100%;
    bottom: 4%;
    justify-content: center;
  }
  .container .Whitehouse .memes img {
    width: 35%;
  }
  .container .Whitehouse .house #loaderImg img{
    width: 50px;
  }
  .container .Whitehouse .house #loaderImg{
    top: 30%;
  }
  .contract-display {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.9);
    padding: 10px 20px;
    border-radius: 8px;
    z-index: 1000;
    text-align: center;
    width: 90%;
    max-width: 400px;
    border: 2px solid rgba(255, 255, 255, 0.2);
  }

  .contract-display p {
    color: white;
    font-size: 3.5vw;
    margin: 0;
    word-break: break-all;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }

  .mobile-contract {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(90deg, #b22234, #ffffff, #3c3b6e);
    padding: 2px;
    z-index: 9999;
  }

  .mobile-contract-inner {
    background: rgba(0, 0, 0, 0.95);
    padding: 8px;
    text-align: center;
  }

  .mobile-contract p {
    color: white;
    font-size: 3.5vw;
    margin: 0;
    word-break: break-all;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
}

@media screen and (max-width: 768px) {
    .container .About {
        padding: 20px;
    }
    
    .container .About div {
        width: 100%;
        padding: 15px;
    }
    
    .container .About h2 {
        font-size: 6vw;
        margin-bottom: 10px;
    }
    
    .container .About p {
        font-size: 3.5vw;
        line-height: 1.4;
        text-align: center;
    }
    
    .container .About #people {
        width: 90%;
        margin: 0 auto;
        padding-top: 20px;
    }

    .container .home .console .consoleLayer .consoleCenter {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .container .home .console .consoleLayer .consoleCenter img[alt="console-top"] {
        width: 100%;
        transform: rotate(180deg);
        margin-bottom: -2px;
    }

    .container .home .console .consoleLayer .consoleCenter img[alt="console"] {
        width: 100%;
        margin-top: -15px;
    }

    .container .home .console .consoleLayer .consoleCenter .center {
        position: relative;
        z-index: 2;
        padding: 1px;
    }

    .container .home .console .consoleLayer .consoleCenter .center h2 {
        font-size: 5vw;
    }

    .container .home .console .consoleLayer .consoleCenter .center h3 {
        font-size: 7vw;
    }

    .container .home .console .consoleLayer .consoleCenter .center p {
        font-size: 4vw;
    }

    .container .home .console .consoleLayer {
        width: 100%;
    }
    
    .container .home .console .consoleLayer .consoleCenter {
        width: 100%;
    }
    
    .container .home .console .consoleLayer .consoleBottom {
        width: 100%;
    }
    
    .container .home .console .consoleLayer .consoleBottom img {
        width: 100%;
        margin-top: -10px;
    }
}