@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
      min-height: 100%;
      background: #eae7e1;
  }

body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;

}

nav {
    position: fixed;
    width: 100%;
    padding: 5px 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      box-shadow: inset 0 0 100px rgba(0,0,0,0.5);
    z-index: 100;
    
    background: 
    linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 80%), /* Transparent top */
    linear-gradient(to right, #3c3b6e 0%, #3c3b6e 40%, transparent 80%), /* Blue background for stars on the left */
    repeating-linear-gradient(white 0%, white 10%, #b22234 10%, #b22234 20%);
    
    background-size: 100% 100px, /* Transparent gradient */
                     40% 100%,   /* Blue field for stars */
                     100% 60%;   /* Red and white stripes */
    
    background-position: 0 0, 0 0, 0 0; /* Align backgrounds to start at the top */
    background-repeat: no-repeat;
    
    height: 100px; /* Adjust height as needed */
  }
  
nav .logo {
  display: flex;
  align-items: center;
  padding-top: 3%;
}
nav .logo img {
  height: 45px;
  width: auto;
  
}

nav ul {
  list-style: none;
  display: flex;
  padding-top: 3%;
}
nav ul li {
  margin-left: 1.5rem;
}
nav ul li a {
  text-decoration: none;
  color: #000;
  font-size: 95%;
  font-weight: 400;
  padding: 4px 8px;
  border-radius: 5px;
}

nav ul li a:hover {
  background-color: #b22234;
  color: white;
}

nav .social-links li {
    margin-left: 0.6rem;
  }
  
  nav .social-links li a {
    text-decoration: none;
    padding: 4px 8px;
    border-radius: 50%;
  }
  nav .social-links li a img{
    width: 40px;
    height: 40px;
  }
  nav .social-links li .buy{
    height: 40px;
    width: 150px;
    background-image: url(../public/images/IMG_1071.PNG);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 900;
    font-size: 95%;
    cursor: pointer;
    text-wrap: nowrap;
  }
  nav .social-links li a:hover {
    background-color: transparent;
  }
  /*
  nav .social-links li a:hover {
    background-image: conic-gradient(
      from 0deg,
      #b22234 0deg 10deg,
      #fff 10deg 20deg,
      #3c3b6e 20deg 30deg,
      #b22234 30deg 40deg,
      #fff 40deg 50deg,
      #3c3b6e 50deg 60deg,
      #b22234 60deg 70deg,
      #fff 70deg 80deg,
      #3c3b6e 80deg 90deg,
      #b22234 90deg 100deg,
      #fff 100deg 110deg,
      #3c3b6e 110deg 120deg,
      #b22234 120deg 130deg,
      #fff 130deg 140deg,
      #3c3b6e 140deg 150deg,
      #b22234 150deg 160deg,
      #fff 160deg 170deg,
      #3c3b6e 170deg 180deg,
      #b22234 180deg 190deg,
      #fff 190deg 200deg,
      #3c3b6e 200deg 210deg,
      #b22234 210deg 220deg,
      #fff 220deg 230deg,
      #3c3b6e 230deg 240deg,
      #b22234 240deg 250deg,
      #fff 250deg 260deg,
      #3c3b6e 260deg 270deg,
      #b22234 270deg 280deg,
      #fff 280deg 290deg,
      #3c3b6e 290deg 300deg,
      #b22234 300deg 310deg,
      #fff 310deg 320deg,
      #3c3b6e 320deg 330deg,
      #b22234 330deg 340deg,
      #fff 340deg 350deg,
      #3c3b6e 350deg 360deg
    );
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: #fff;
  }
    */

.section {
  position: relative;
}

span[id^="section-"] {
  top: -100px;
  position: absolute;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.hamburger .line {
  width: 25px;
  height: 1px;
  background-color: #802430;
  display: block;
  margin: 4px auto;
  transition: all 0.3s ease-in-out;
}
.hamburger-active {
  transition: all 0.3s ease-in-out;
  transition-delay: 0.6s;
  transform: rotate(45deg);
}

.hamburger-active .line:nth-child(2) {
  width: 0px;
}

.hamburger-active .line:nth-child(1),
.hamburger-active .line:nth-child(3) {
  transition-delay: 0.3s;
}

.hamburger-active .line:nth-child(1) {
  transform: translateY(12px);
}

.hamburger-active .line:nth-child(3) {
  transform: translateY(-7px) rotate(90deg);
}

.container {
    width: 100%;
    height: fit-content;
    position: absolute;
    top: 100px;
}
.container .console{
    position: absolute;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    top: 150px;
    overflow: hidden;
}
.container .console .consoleLayer{
    display: flex;
    flex-direction: column;
}
.container .console .consoleLayer div{
    display: flex;
}
.container .console .consoleLayer .consoleTop {
    display: flex;
    align-items: center;
    justify-content: center;
}
.container .console .consoleLayer .consoleTop #timer{
    display: flex;
    position: absolute;
    padding-top: 60px;
    width: 300px;
    height: 100px;
    justify-content: center;
    align-items: center;
}
.container .console .consoleLayer .consoleTop #timer #timerDisplay {
    position: absolute;
    color: white;
    z-index: 2;
}
.container .console .consoleLayer .consoleTop #timer #timerDisplay em {
  font-size: 14px;
  margin-right: 1em;
}
.container .console .consoleLayer .consoleTop #timer .txt {
    position: absolute;
    top: 100%;
    color: red;
    width: 640px;
    text-align: center;
    font-size: 20px;
    padding: 0;
    margin: 11px 0 0;
    text-transform:uppercase;
    letter-spacing: -0.5px;
}
.container .console .consoleLayer .consoleTop #timer img{
    width: 100%;
    height: auto;
    position: relative;
}
.container .console img{
    width: 640px;
    height: auto;
}
.container .console #flag {
    width: 400px;
}
.container .console #flag:nth-of-type(2){
    transform: scaleX(-100%);
}
/* .container .section{
    width: 100%;
    position: relative;
} */
.container .home{
    height: 875px;
    width: 100%;
    display: flex;
    justify-content: center;
    box-shadow: inset 0 0 200px rgba(0,0,0,0.5);
}


.container .Whitehouse{
    height: 1100px;
}

@media screen and (max-width: 768px) {
    .container .Whitehouse {
        height: 500px;
    }
}

.container .home .faces{
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 60px;
}
.container .home .faces .faceRow {
    padding: 0 1%;
}
.container .home .faces .faceRow:nth-of-type(1){
    display: flex;
    flex-direction: column;
    top: 145px;
    position: relative;
    z-index: 1;
}
.container .home .faces .faceRow:nth-of-type(2){
    height: fit-content;
    width: 640px;
    position: relative;
    display: flex;
    align-content: flex-start;
    justify-content: space-around;
    flex-direction: row;
    padding: 0;
    align-items: flex-end;
}

.container .home .faces .faceRow:nth-of-type(3){
    display: flex;
    flex-direction: column;
    transform: scaleX(-100%);
    top: 145px;
    position: relative;
    z-index: 1;
}
.container .home .faces img {
    width: 175px;
    height: auto;
}
.container .home .faces .faceRow:nth-of-type(2) img:nth-of-type(1) {
    transform: translateY(-20px);
}
.container .home .faces .faceRow:nth-of-type(2) img:nth-of-type(2) {
    transform: translate(-14px, -25px);
}
.container .home .faces .faceRow:nth-of-type(2) img:nth-of-type(3) {
    transform: scaleX(-100%) translateY(-20px);
}
.container .home .console .consoleLayer .consoleCenter .top{
    position: absolute;
    display: flex;
    width: 640px;
    justify-content: space-evenly;
    padding-top: 1%;
    align-items: center;
  }
  .container .home .console .consoleLayer .consoleCenter .top h2{
    font-size: 25px;
    color: white;
    margin: 0;
  }
  .container .home .console .consoleLayer .consoleCenter .top .progressBar{
    width: 50%;
    display: flex;
    overflow: hidden; 
    position: relative;
  }
  .container .home .console .consoleLayer .consoleCenter .top .progressBar span{
    position: absolute;
    display: flex;
    width: 100%;
    left: 0;
    top: 0;
    height: 40px;
    /* background-color: red; */
    z-index: 1; 
    mask-image: url('../public/images/ProgressMask.png'); /* Mask with the image */
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
  }
  .container .home .console .consoleLayer .consoleCenter .top .progressBar span::before {
    transition: width 0.3s ease; /* Smooth transition for progress */
  }
  .container .home .console .consoleLayer .consoleCenter .top .progressBar img{
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  .container .home .console .consoleLayer .consoleCenter .center{
    position: absolute;
    padding-top: 40px;
    display: flex;
    width: 640px;
    justify-content: center;
    color: white;
  }
  .container .home .console .consoleLayer .consoleCenter .center h2{
    font-size: 20px;
    text-align: center;
  }
  .container .home .console .consoleLayer .consoleCenter .bottom{
    position: absolute;
    padding-top: 170px;
    width: 640px;
    display: flex;
    justify-content: center;
  }
  .container .home .console .consoleLayer .consoleCenter .bottom img {
    width: 100px;
    height: 100px;
    transition: 0.3s ease; /* Smooth transition */
    cursor: pointer;
  }
  .container .home .console .consoleLayer .consoleCenter .bottom img:nth-child(1):hover{
    content: url('../public/images/hoveETH.png'); /* Hover image for ETH */
  }
  .container .home .console .consoleLayer .consoleCenter .bottom img:nth-child(2):hover{
    content: url('../public/images/hoveUSDT.png'); /* Hover image for ETH */
  }
  .container .home .console .consoleLayer .consoleCenter .bottom img:nth-child(3):hover{
    content: url('../public/images/hoveCard.png'); /* Hover image for ETH */
  }
  .container .home .console .consoleLayer .consoleBottom{
    position: relative;
    width: 640px;
  }
  .container .home .console .consoleLayer .consoleBottom .left,.container .home .console .consoleLayer .consoleBottom .right{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 320px;
    z-index: 5;
    margin-top: -5%;
  }
  .container .home .console .consoleLayer .consoleBottom .left p,.container .home .console .consoleLayer .consoleBottom .right p{
    width: fit-content;
    display: flex;
    justify-content: space-evenly;
    margin: 0 5%;
    background-color: rgba(230, 230, 230, 0.85);
    padding: 0 5%;
    border-radius: 10px;
    white-space: nowrap;
  }
  /* .container .home .console .consoleLayer .consoleBottom div.btn-group {
    position: absolute;
    border: none;
    outline: none;
    bottom: 32%;
    height: 60px;
    width: 100%;
    background: transparent url(../public/images/IMG_1071.PNG);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    color: white;
    font-weight: 900;
    cursor: pointer;
    text-wrap: nowrap;
    font-size: 20px;
  } */
  .container .home .console .consoleLayer .consoleBottom button {
    position: absolute;
    border: none;
    outline: none;
    bottom: 32%;
    height: 60px;
    padding: 10px 45px;
    left: 50%;
    transform: translate(-50%, 0);
    background: transparent url(../public/images/IMG_1071.PNG);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    color: white;
    font-weight: 900;
    cursor: pointer;
    text-wrap: nowrap;
    font-size: 20px;
  }
  .container .home .console .consoleLayer .consoleBottom .left p span {
    margin-left: 10px;
  }
  .container .home .console .consoleLayer .consoleBottom .left p span:nth-of-type(2){
    color: red;
    font-weight: 900;
    cursor: pointer;
  }
  .container .home .console .consoleLayer .consoleBottom .left div,.container .home .console .consoleLayer .consoleBottom .right div{
    flex-direction: row;
    background: none;
    background-image: url(../public/images/inputWhite.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin: 0 5%;
    background-position: center;
    padding: 0;
  }
  .container .home .console .consoleLayer .consoleBottom .left div input,.container .home .console .consoleLayer .consoleBottom .right div input {
    width: 100%;
    background: none;
    border: none;
    outline: none;
    padding-left: 10px;
    font-size: 20px;
  }
  .container .home .console .consoleLayer .consoleBottom .left div input,.container .home .console .consoleLayer .consoleBottom .right div button {
    width: 100%;
    background: none;
    border: none;
    outline: none;
    padding-left: 10px;
    font-size: 20px;
  }
  .container .home .console .consoleLayer .consoleBottom .left div img,.container .home .console .consoleLayer .consoleBottom .right div img{
    width: 30px;
    height: auto;
    margin: 5%
  }
  .container .home .console .consoleLayer .consoleBottom .right{
    left: 50%;
  }
  .container .About{
    height: fit-content;
    box-shadow: inset 0 0 200px rgba(0, 0, 0, 0.5);
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
}
.container .About #people{
    position: absolute;
    bottom: 0;
}
.container .About img{
    width: 100%;
    height: auto;
}
.container .About div {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    right: 3%;
}
.container .About div h2{
    font-size: 50px;
    color: blue;
}
.container .About div p{
    width: 100%;
    text-align: center;
    font-size: 25px;
}
.container .Tokenomics{
  display: flex;
  height: 1100px;
  box-shadow: inset 0 0 200px rgba(0, 0, 0, 0.5);
  justify-content: center;
  width: 100%;
  padding-top: 30px;
}
.container .Tokenomics .container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  margin: 0;
  perspective: 1000px;
  position: relative;
  width: 80%;
  height: 690px;
  transform-style: preserve-3d;
  transition: transform 0.1s ease-out;
}

.container .Tokenomics .box {
  transform-style: preserve-3d;
  position: absolute;
  padding: 20px;
  text-align: center;
  font-weight: bold;
  transition: transform 0.3s ease, z-index 0.3s ease;
  display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.container .Tokenomics .box p {
  perspective: 100px;
  transition: transform 0.3s ease, z-index 0.3s ease;
  padding: 0 10px;
}
.container .Tokenomics .box:hover {
  transform: scale(1.2) translateZ(100px); /* Scaling only the box */
  z-index: 10; /* Bring box to front */
}
.container .Tokenomics .box:hover p{
  color: red;
  transform:translateZ(10px);
  /* text-shadow: 0 0 5px rgb(255, 241, 118), 0 0 5px rgba(255, 241, 118); */
}
.container .Tokenomics .center-chart {
  position: absolute;
  width:100%;
  height: 100%;
  background-image: url(../public/images/center.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}


.container .Tokenomics .marketing {

  top: -5%;
  left: 0%;
  width: 300px;
  height: 220px;
  background-image: url(../public/images/marketing.png);
}

.container .Tokenomics .lp {
  top: -5%;
  right: 15%;
  width: 550px;
  height: 150px;
  background-image: url(../public/images/LP.png);
}
.container .Tokenomics .lp p {
  margin-top: 10%;
}

.container .Tokenomics .presale {
  top: 30%;
  left: 0;
  width: 300px;
  height: 450px;
  background-image: url(../public/images/persale.png);

}

.container .Tokenomics .development {
  top: 22%;
  right: 0;
  width: 445px;
  height: 226px;
  background-image: url(../public/images/development.png);
}

.container .Tokenomics .cex {
  bottom: -10%;
  left: 30%;
  width: 450px;
  height: 280px;
  background-image: url(../public/images/cex.png);
}

.container .Tokenomics .donations {
  bottom: 0%;
  right: 0;
  width: 410px;
  height: 300px;
  background-image: url(../public/images/donation.png);
}
.container .Tokenomics #diagram {
  transition: transform 0.5s ease;
}
.container .Whitehouse {
  height: 1350px;
}
.container .Whitehouse #bgFlags{
  width: 100%;
  height: auto;
}
.container .Whitehouse .flag-container {
  width: 100%;
  height: auto;
  display: flex;
  position: absolute;
  flex-direction: row;
  z-index: 0;
  padding-top: 50px;
  background-image: url(../public/images/strok.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform-origin: top;
  justify-content: space-evenly;
}
.container .Whitehouse .flag-container .flag {
  width: calc(100% / 13);
  height: 100%;
  display: flex;
  flex-direction: row;
  animation: flag-wave 5s infinite ease-in-out;
  transform-origin: top;
}
.container .Whitehouse .flag-container .flag img{
  width: 100%;
}
@keyframes flag-wave {
  0% {
      transform: rotateX(-30deg);
  }
  50% {
      transform: rotateX(30deg);
  }
  100% {
      transform: rotateX(-30deg);
  }
}
.container .Whitehouse .house {
  width: 60%;
    height: 600px;
    position: absolute;
    left: 50%;
    margin-top: 13%;
    transform: translateX(-50%);
}
.container .Whitehouse .memes {
    position: absolute;
    display: flex;
    width: fit-content;
    height: fit-content;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
    gap: 20px;
}
.container .Whitehouse .memes img {
    width: 400px;
    height: auto;
}
.container .Whitehouse .house #loaderImg {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%,-50%);
}
.container .Whitehouse .house #loaderImg img{
  width: 100px;
  height: auto;
}
.container .Whitehouse .house #loaderImg span{
  width: fit-content;
}
.menubar {
  display: none;
}

.container .home .console .consoleLayer .consoleCenter img[alt="console-top"] {
    position: absolute;
    top: 60px;
    width: 640px;
    height: auto;
    transform: rotate(180deg);
    z-index: 1;
}

.container .home .console .consoleLayer .consoleBottom {
    position: relative;
    width: 640px;
}

.container .home .console .consoleLayer .consoleBottom img {
    width: 640px;
    height: auto;
}

.container .home .console .consoleLayer .consoleCenter .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 20px;
    text-align: center;
}

.container .home .console .consoleLayer .consoleCenter .center h2 {
    margin: 0;
    line-height: 1.2;
}

.container .home .console .consoleLayer .consoleCenter .center h3 {
    margin: 15px 0;
    font-size: 2.5em;
    color: white;
}

.container .home .console .consoleLayer .consoleCenter .center p {
    margin: 0;
    color: white;
    font-size: 1em;
}

.contract-address {
    margin-top: 20px;
    background: rgba(0, 0, 0, 0.6);
    padding: 15px;
    border-radius: 8px;
    width: 100%;
    max-width: 600px;
}

.contract-address p {
    color: #fff;
    margin-bottom: 10px;
    font-size: 16px;
}

.address-box {
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    padding: 10px;
    border-radius: 6px;
    gap: 10px;
}

.address-box code {
    color: #fff;
    font-family: monospace;
    font-size: 14px;
    word-break: break-all;
}

.address-box button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
}

.address-box button:hover {
    opacity: 0.8;
}

.address-box button img {
    width: 20px;
    height: 20px;
}

